<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Tela de Pedidos</h4>
                      <p class="mb-30">
                        Tela da lista de pedidos manuais ou do site.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Cliente</label>
                                <input class="form-control" type="text" placeholder="" v-model="nomeCliente" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Pedido</label>
                                <input class="form-control" type="number" placeholder="" v-model="numPedido" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Status</label>
                                <v-select v-model="Pedido.situacaoPedido"
                                          :options="[
                            { nome: 'Aguardando', valor: '1' },
                            { nome: 'Agendado', valor: '2' },
                            { nome: 'Enviado', valor: '3' },
                            { nome: 'Entregue', valor: '4' },
                            { nome: 'Cancelado', valor: '5' },
                          ]"
                                          single-line
                                          :reduce="(x) => x.nome"
                                          label="nome"
                                          class="col-12"></v-select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data Pedido</label>
                                <input class="form-control"
                                       type="date"
                                       placeholder=""
                                       v-model="Data"
                                       @change="MudaData" />
                            </div>
                        </div>
                    </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        class="btn btn-link"
                        @click="$redirect_reload('/controle/listapedidos')"
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="Pesquisar"
                      >
                        Pesquisar
                      </button>
                      <!-- <a
                        @click="$redirect_reload('/controle/pedidomanual')"
                        class="btn btn-success"
                        id="sa-custom-position"
                        >Novo Pedido</a
                      > -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- Default Basic Forms Start -->
        <div class="pd-20 card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
                <tr>
                    <th>Id</th>
                    <!-- <th class="datatable-nosort">Ação</th> -->
                    <th class="datatable-nosort"></th>
                    <th class="datatable-nosort"></th>
                    <th>Cliente</th>
                    <th>TID Cielo</th>
                    <th class="datatable-nosort">Valor</th>
                    <th class="datatable-nosort">Status</th>
                    <th class="datatable-nosort">Data</th>
                    <!-- <th class="datatable-nosort"></th>
    <th class="datatable-nosort"></th> -->


                </tr>
            </thead>
            <tbody>
                <tr v-for="(Pedido, Index) in Pedidos" :key="Index">
                    <td>{{ Pedido.pedidoId }}</td>
                    <!-- <td>
      <a class="dropdown-item tamdado" @click="SalvarItem(Pedido)"
        ><i class="dw dw-edit2 tamdado"></i> Salvar</a
      >
    </td> -->
                    <td class="tamdado">
                        <a href="#"
                           class="btn-block"
                           data-toggle="modal"
                           :data-target="'#modal' + Pedido.pedidoId"
                           type="button"
                           title="Ver Detalhes">
                            <i class="icon-copy dw dw-map tamicone"></i>
                        </a>
                    </td>
                    <td class="tamdado">
                        <a @click="
                      $redirect('/controle/pedido/' + Pedido.pedidoId)
                    ">
                            <i class="icon-copy dw dw-folder1 tamicone"></i>
                        </a>
                    </td>
                    <td class="tamdado">
                        <span v-if="Pedido.cliente != null">
                            {{
                    Pedido.cliente.nomeEmpresa
                            }}
                        </span><span v-else>###</span>
                    </td>
                    <td class="tamdado">
                        <span>
                            {{ Pedido.tidCielo }}
                        </span>
                    </td>
                    <td class="tamdado">
                        R$
                        {{
                    CalculaValorPedido(Pedido)
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                      .replace(".", ",")
                      .replace("$", "")
                        }}
                    </td>
                    <td>
                        <a :class="Pedido.situacaoPedido + ' btn-block'
                    "
                           type="button"
                           title="Ver Detalhes"
                           style="
                      padding: 3px;
                      border-radius: 4px;
                      color: white;
                      font-size: 11px;
                      text-align: center;
                      font-weight: bold;
                    "
                           @click="$redirect_reload('/controle/pedido/' + Pedido.pedidoId)">
                            {{ Pedido.situacaoPedido }}
                        </a>
                    </td>
                    <td class="tamdado">
                        {{ sqlToJsDate(Pedido.dataPedido) }}
                    </td>
                    <!-- <td class="tamdado">
      <a
        href="#"
        class="btn-block"
        data-toggle="modal"
        data-target="#Medium-modal"
        type="button"
        title="Ver Mensagem"
      >
        <i class="icon-copy dw dw-email tamicone"></i>
      </a>
    </td>
    <td class="tamdado">
      <a
        class="btn-block"
        data-toggle="modal"
        data-target="#Medium-modal2"
        type="button"
        title="Ver Imagem"
      >
        <i class="icon-copy dw dw-photo-camera tamicone"></i>
      </a>
    </td> -->


                    <div class="modal fade bs-example-modal-lg"
                         :id="'modal' + Pedido.pedidoId"
                         tabindex="-1"
                         role="dialog"
                         aria-labelledby="myLargeModalLabel"
                         aria-hidden="true">
                        <div class="modal-dialog modal-lg modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title" id="myLargeModalLabel">
                                        Detalhes
                                    </h4>
                                    <button type="button"
                                            class="close"
                                            data-dismiss="modal"
                                            aria-hidden="true">
                                        ×
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <span v-if="listaPedidoAgendamento.find(x => x.pedido != null && x.pedido.pedidoId == Pedido.pedidoId) != null">
                                                    Data Entrega:
                                                    {{
                                  sqlToJsDate(
                                    listaPedidoAgendamento.find(x => x.pedido != null && x.pedido.pedidoId == Pedido.pedidoId).diaEntrega
                                  ) +
                                  " - " +
                                  listaPedidoAgendamento.find(x => x.pedido != null && x.pedido.pedidoId == Pedido.pedidoId).horaIni.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
}) +
                                  ":" +
                                  listaPedidoAgendamento.find(x => x.pedido != null && x.pedido.pedidoId == Pedido.pedidoId).minIni.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
}) +
                                  " as " +
                                  listaPedidoAgendamento.find(x => x.pedido != null && x.pedido.pedidoId == Pedido.pedidoId).horaFim.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
}) +
                                  ":" +
                                  listaPedidoAgendamento.find(x => x.pedido != null && x.pedido.pedidoId == Pedido.pedidoId).minFim.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
})
                                                    }}
                                                </span>
                                                <br />
                                                <span style="font-weight: 600"
                                                      v-for="(PedidoTmp,index5) in listaPedidoEndereco.filter(x => x.pedido != null && x.pedido.pedidoId == Pedido.pedidoId)"
                                                      :key="index5">
                                                    <section v-if="PedidoTmp.endereco != null">
                                                        Endereço Entrega:{{
                                  PedidoTmp.endereco +
                                  ", " +
                                  PedidoTmp.numero +
                                  ", " +
                                  PedidoTmp.complemento
                                                        }}
                                                        <br />
                                                        {{
                                  PedidoTmp.bairro != null &&
                                  PedidoTmp.bairro.length > 0
                                    ? PedidoTmp.bairro + ", "
                                    : ""
                                                        }}
                                                        {{
                                  PedidoTmp.cidade != null
                                    ? PedidoTmp.cidade.nomeCidade +
                                      " / "
                                    : ""
                                                        }}
                                                        {{
                                  PedidoTmp.estado != null
                                    ? PedidoTmp.estado.nomeEstado +
                                      " "
                                    : ""
                                                        }}
                                                        {{
                                  PedidoTmp.cep != null &&
                                  PedidoTmp.cep.length > 0
                                    ? "CEP: " + PedidoTmp.cep
                                    : ""
                                                        }}
                                                    </section>
                                                    <!-- Endereço Entrega: Rua xxxxxxxxx xxxxxxxxx, 249 / 01<br />
                                    Nome do Bairro, Cidade / Estado CEP: 99999-99-->
                                                </span>
                                            </div>
                                            <div class="col-lg-12">
                                                Tipo Pagamento: {{ Pedido.formaPgto }}
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="table-responsive">
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Id</th>
                                                                <th>Produto</th>
                                                                <th class="datatable-nosort">Ref.</th>
                                                                <th class="datatable-nosort">Unit</th>
                                                                <th class="datatable-nosort">Qtde</th>
                                                                <th class="datatable-nosort">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(
                                                                Item, Index2
                                                                ) in Pedido.listaPedidoItem.filter(
                                                                (x)=>
                                                                x.produto != null
                                                                )"
                                                                :key="Index2"
                                                                >
                                                                <td></td>
                                                                <td class="tamdado">
                                                                    <span v-if="Item.produto != null">
                                                                        {{
                                          Item.produto.nomeProduto
                                                                        }}
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <span v-if="Item.produto != null">
                                                                        {{
                                          Item.produto.referencia
                                                                        }}
                                                                    </span>
                                                                </td>
                                                                <td class="tamdado">
                                                                    <span v-if="Item.produto != null">
                                                                        {{
                                          Item.valorProduto
                                            .toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                            })
                                            .replace(".", ",")
                                            .replace("$", "")
                                                                        }}
                                                                    </span>
                                                                </td>
                                                                <td class="tamdado">
                                                                    {{ Item.qtdeProduto }}
                                                                </td>
                                                                <td class="tamdado">
                                                                    {{
                                          Item.valorTotalProduto
                                            .toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                            })
                                            .replace(".", ",")
                                            .replace("$", "")
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr v-for="(
                                        Item, Index3
                                      ) in Pedido.listaPedidoitemAdicional"
                                                                :key="Index3">
                                                                <td>
                                                                    {{ Item.itemAvulso.itemAvulsoId }}
                                                                </td>
                                                                <td class="tamdado">
                                                                    {{ Item.itemAvulso.nomteItemAvulso }}
                                                                </td>

                                                                <td>
                                                                    AV{{ Item.itemAvulso.itemAvulsoId }}
                                                                </td>
                                                                <td class="tamdado">
                                                                    {{
                                          Item.valorTotalProduto
                                            .toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                            })
                                            .replace(".", ",")
                                            .replace("$", "")
                                                                    }}
                                                                </td>
                                                                <td class="tamdado">{{Item.qtdeProduto}}</td>
                                                                <td class="tamdado">
                                                                    {{
                                          Item.valorProduto
                                            .toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                            })
                                            .replace(".", ",")
                                            .replace("$", "")
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr v-for="(
                                                                Item, Index4
                                                                ) in (Pedido.listaPedidoItemManual !=null && Pedido.listaPedidoItemManual.length>
                                                                0 ? Pedido.listaPedidoItemManual.filter(x => x.itemAvulsoManual != null):[])"
                                                                :key="Index4"
                                                                >
                                                                <td>
                                                                    {{
                                          Item.itemAvulsoManual
                                            .itemAvulsoManualId
                                                                    }}
                                                                </td>
                                                                <td class="tamdado">
                                                                    {{Item.itemAvulsoManual.nomeItem}}
                                                                </td>

                                                                <td>AVM{{Item.itemAvulsoManual.itemAvulsoManualId}}</td>
                                                                <td class="tamdado">
                                                                    {{
                                          Item.valorTotalProduto
                                            .toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                            })
                                            .replace(".", ",")
                                            .replace("$", "")
                                                                    }}
                                                                </td>
                                                                <td class="tamdado">{{Item.qtdeProduto}}</td>
                                                                <td class="tamdado">
                                                                    {{
                                          Item.valorProduto
                                            .toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                            })
                                            .replace(".", ",")
                                            .replace("$", "")
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div class="col-lg-12"
                                                         style="text-align: right">
                                                        Frete ({{ Pedido.tipoFrete }}): R$
                                                        {{
                                    Pedido.valorFrete
                                      .toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })
                                      .replace(".", ",")
                                      .replace("$", "")
                                                        }}
                                                    </div>
                                                    <div class="col-lg-12"
                                                         style="text-align: right">
                                                        Cupom: R$
                                                        {{
                                    Pedido.valorCupom
                                      .toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })
                                      .replace(".", ",")
                                      .replace("$", "")
                                                        }}
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div style="
                                      font-size: 15px;
                                      font-weight: 800;
                                      text-align: right;
                                    ">
                                                            Valor Total R$
                                                            {{
                                      CalculaValorPedido(Pedido)
                                        .toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })
                                        .replace(".", ",")
                                        .replace("$", "")
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button"
                                            class="btn btn-secondary"
                                            data-dismiss="modal">
                                        Fechar
                                    </button>
                                    <!-- <a href="PedPedidosInvoice.html" class="btn btn-primary"
                      >Gerar Invoice</a
                    > -->
                                </div>
                            </div>
                        </div>
                    </div>
                </tr>
            </tbody>
          </table>
        </div>
        <!--modal mensagem-->
        <!-- <div
          class="modal fade"
          id="Medium-modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="myLargeModalLabel">Mensagem</h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
              </div>
              <div class="modal-body">
                <img
                  src="images/img-mensagem.jpg"
                  style="width: 100%; height: auto"
                />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
                <button type="button" class="btn btn-primary">
                  <i class="icon-copy dw dw-print"></i>Imprimir
                </button>
              </div>
            </div>
          </div>
        </div> -->
        <!--modal mensagem-->
        <!-- <div
          class="modal fade"
          id="Medium-modal2"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-titl e" id="myLargeModalLabel">Imagem</h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
              </div>
              <div class="modal-body">
                <img
                  src="images/img-foto.jpg"
                  style="width: 100%; height: auto"
                />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
                <button type="button" class="btn btn-primary">
                  <i class="icon-copy dw dw-print"></i>Imprimir
                </button>
              </div>
            </div>
          </div>
        </div> -->

        <!--modal mensagem-->
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";
//import moment from "moment";
import * as momentTemp from "moment";
const moment = momentTemp["default"];

export default {
  data() {
    return {
        nomeCliente: '',
        numPedido: 0,
      Clientes: [],
      Pedido: {
          cliente: { clienteId: 0, nomeEmpresa: '' },
        situacaoPedido: "",
        dataPedido: "",
      },
      Data: "",
      Pedidos: [],
      listaPedidoEndereco: [],
      listaPedidoAgendamento: [],
      listaPedidoPagtoComprova: [],
      T: $(".data-table").DataTable(),
    };
  },
  methods: {
    sqlToJsDate(sqlDate) {
      //alert(sqlDate)
      sqlDate = sqlDate.replace("T", " ");
      if (sqlDate != null && sqlDate.length > 0) {
        //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
        var sqlDateArr1 = sqlDate.split("-");
        //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
        var sYear = sqlDateArr1[0];
        var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
        var sqlDateArr2 = sqlDateArr1[2].split(" ");
        //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
        var sDay = sqlDateArr2[0];
        var sqlDateArr3 = sqlDateArr2[1].split(":");
        //format of sqlDateArr3[] = ['hh','mm','ss.ms']
        var sHour = sqlDateArr3[0];
        var sMinute = sqlDateArr3[1];
        var sSecond = sqlDateArr3[2];
        // var sqlDateArr4 = sqlDateArr3[2].split(".");
        // //format of sqlDateArr4[] = ['ss','ms']
        // var sSecond = sqlDateArr4[0];
        // var sMillisecond = sqlDateArr4[1];

        return new Date(
          sYear,
          sMonth,
          sDay,
          sHour,
          sMinute,
          sSecond,
          0
        ).toLocaleDateString("pt-BR");
      } else {
        return "";
      }
    },
    SalvarItem(Pedido) {
      this.$http.put(this.$apiUrl + "/pedido/" + Pedido.pedidoId, Pedido).then(
        (response) => {
          if (response.ok) {
            this.$mensagem_sucesso("Pedido alterado com sucesso.");
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    CalculaValorPedido(Pedido) {
      var total = 0;
      total += Pedido.valorFrete;
      total -= Pedido.valorCupom;

      if (Pedido.listaPedidoItem != null && Pedido.listaPedidoItem.length > 0) {
        Pedido.listaPedidoItem.forEach((item) => {
          total += (item.valorProduto * item.qtdeProduto);
        });
      }

      if (
        Pedido.listaPedidoItemAdicional != null &&
        Pedido.listaPedidoItemAdicional.length > 0
      ) {
        Pedido.listaPedidoItemAdicional.forEach((item) => {
          total += (item.valorProduto * item.qtdeProduto);
        });
      }

      if (
        Pedido.listaPedidoItemManual != null &&
        Pedido.listaPedidoItemManual.length > 0
      ) {
        Pedido.listaPedidoItemManual.forEach((item) => {
          total += (item.valorProduto * item.qtdeProduto);
        });
      }

      return total;
    },
    MudaData() {
      this.Pedido.dataPedido = moment(this.Data)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T")
        .toString();
    },
    Pesquisar() {
        this.Pedido.cliente = { clienteId: 0, nomeEmpresa: '' };
        this.Pedido.pedidoId = 0;
      if (this.Pedido.dataPedido == null || this.Pedido.dataPedido.length <= 0)
        this.Pedido.dataPedido = "0001-01-01T00:00:01";

      if(this.Pedido.cliente == null || this.Pedido.cliente.clienteId == null){
          this.Pedido.cliente = { clienteId: 0, nomeEmpresa: '' };
      }
        if (this.nomeCliente != '') {
            this.Pedido.cliente = { clienteId: 0, nomeEmpresa: this.nomeCliente };
        }

        if (this.numPedido > 0) {
            this.Pedido.pedidoId = parseInt(this.numPedido, 10);
        }
      console.log("POST");
      console.log(this.Pedido);
      this.$http.post(this.$apiUrl + "/pedido/busca", this.Pedido).then(
        (response) => {
              if (response.ok) {
                  this.Pedidos = response.body;
            this.Pedidos = this.Pedidos.filter(x => x.cliente != null);
            this.Pedidos.reverse()
            console.log('aaaa');
            console.log(this.Pedidos);
            if(this.Pedidos == null || this.Pedidos.length <= 0)
            {
               this.$mensagem_erro('Nenhum resultado encontrado.');
            }
            else
            {
              //var cont = 0;
              //for(cont = 0; cont < this.Pedidos.length; cont++)
              //{
              //  //this.$http.get(this.$apiUrl + "/pedidoagendamento/pedido/" + this.Pedidos[cont].pedidoId)
              //  //.then(response => {
              //  //  let tmp = response.body;
              //  //  if(tmp != null && tmp != "")
              //  //    this.listaPedidoAgendamento.push(tmp);
              //  //});
              //  //this.$http.get(this.$apiUrl + "/pedidopagtocomprova/pedido/" + this.Pedidos[cont].pedidoId)
              //  //.then(response => {
              //  //    let tmp2 = response.body;
              //  //  if(tmp2 != null && tmp2 != "")
              //  //    this.listaPedidoPagtoComprova.push(tmp2);
              //  //});
              //  //this.$http.get(this.$apiUrl + "/pedidoendereco/pedido/" + this.Pedidos[cont].pedidoId)
              //  //.then(response => {
              //  //    let tmp3 = response.body;
              //  //  if(tmp3 != null && tmp3 != "")
              //  //    this.listaPedidoEndereco.push(tmp3);
              //  //});
              //}
            }
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    Limpar() {
      this.$cookie.delete("listap");
      this.$redirect_reload("/controle/listaprodutos");
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 200,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
    
  },
  components: {
    vSelect,
  },
  created() {
    this.Pesquisar();
    //const interval = setInterval(function() {
    //  this.Pesquisar();
    //}, 5000);

    //clearInterval(interval)
        
      
    
    //this.$http.get(this.$apiUrl + "/clientes")
    //.then(response => {
    //  this.Clientes = response.body;
    //}, error => {
    //  console.log("ERRO AO CARREGAR CLIENTES: " + error.bodyText);
    //})
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Aguardando {
  background-color: #f0ad4e !important;
  border-radius: 5px;
}
.Agendado {
  background-color: #31b0d5;
  border-radius: 5px;
}
.Enviado {
  background-color: #337ab7;
  border-radius: 5px;
}
.Entregue {
  background-color: #40bc40;
  border-radius: 5px;
}
.Cancelado {
  background-color: #c9302c;
  border-radius: 5px;
}
button {
  margin: 10px;
}
.tamdado {
  font-size: 13px !important;
}

.tamicone {
  font-size: 20px !important;
}
</style>
