import Vue from "vue";
import App from "./App.vue";
import VueResource from "vue-resource";
import LoadScript from "vue-plugin-load-script";
import router from "./router";
import VueCookie from "vue-cookie";
import swal from "sweetalert";
// import quill-editor from "vue-quill-editor";
import VueQuillEditor from 'vue-quill-editor'


import VueSelect from "vue-select";
import money from "v-money";
import VueMeta from "vue-meta";
import VueHead from "vue-head";


import VueModal from '@kouts/vue-modal'

import VueDraggable from 'vue-draggable'
Vue.use(VueDraggable)


import VueGoogleCharts from 'vue-google-charts'
Vue.use(VueGoogleCharts)

Vue.component('Modal', VueModal)    
var sha1 = require("js-sha1");

Vue.use(VueHead);
Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.use(LoadScript);
Vue.use(VueCookie);

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(VueSelect);
Vue.use(money, { precision: 4 });
Vue.use(VueMeta);

import '@kouts/vue-modal/dist/vue-modal.css'

//define a URL da api
//NO AR
Vue.prototype.$apiUrl = "https://controle.florate.com.br/api";
//LOCAL
//Vue.prototype.$apiUrl = "http://localhost:5000/api";

//Vue.prototype.$baseUrl = "http://localhost:7416";

Vue.prototype.$baseUrl = "https://controle.florate.com.br";


Vue.prototype.$Url = "";
//Vue.prototype.$apiUrl = "http://localhost:7416/api";

Vue.prototype.$redirect_reload = function(path) {
  this.$router.push(path);
  this.$router.go(this.$router.currentRoute);
};

Vue.prototype.$redirect = function(path) {
  this.$router.push(path);
};
Vue.prototype.$sha1 = function(input) {
  return sha1(input);
};
Vue.prototype.$mensagem_sucesso = function(mensagem) {
  swal(" ", mensagem, "success");
};
Vue.prototype.$mensagem_normal = function(mensagem) {
  swal(mensagem);
};
Vue.prototype.$atualiza_cabecalho = function () {
  this.$http
    .get(this.$apiUrl + "/GrupoTelaInstitucional/completo")
    .then((response) => {
      this.Grupos = response.body;
      localStorage.setItem("menusgcad", JSON.stringify(this.Grupos));
    });
};
Vue.prototype.$mensagem_erro = function(mensagem) {
  swal(" ", mensagem, "error");
};
Vue.prototype.$clearHTML = function(escapedHTML) {
  return escapedHTML.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&");
};

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
